import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useEffect, useState, useCallback } from "react";
import { setClientConfigAction } from "src/state/clientConfig/actions";
import styles from "src/Router/styles.module.scss";
import {
  Home,
  Menu,
  Item,
  SignIn,
  SignUp,
  Terms,
  Account,
  PersonalInfo,
  Cart,
  Checkout,
  Catering,
  ForgotPassword,
  ResetPassword,
  Deal,
  Receipt,
  CateringInvoice,
  CateringCart,
  CateringCheckout,
} from "src/pages";
import { useDispatch, useSelector } from "react-redux";
import {
  Footer,
  Header,
  SelectLocationModal,
  SkeletonLoading,
} from "src/components";
import {
  getGenericHomePath,
  getGenericMenuPath,
  getGenericItemPath,
  getGenericSignInPath,
  getGenericSignUpPath,
  getGenericTermsPath,
  getGenericAccountPath,
  getGenericPersonalInfoPath,
  getGenericCartPath,
  getGenericCheckoutPath,
  getGenericWildcardRoute,
  getGenericCateringPath,
  getGenericForgotPasswordPath,
  getGenericResetPasswordPath,
  getGenericDealPath,
  getGenericCategoryPath,
  getGenericReceiptPath,
  getGenericCateringInvoicePath,
  getGenericCateringCartPath,
  getCateringCheckoutPath,
  getGenericCateringCheckoutPath,
} from "src/Router/routes";
import { getItemFromLocalStorage } from "src/common/localStorage";
import { getCustomerFromDatabaseAction } from "src/state/customer/actions";
import { logWebsiteVisitToAnalytics } from "src/common/analytics";
import { initializeState } from "src/state/initializeState";
import { selectLocationAction } from "src/state/location/actions";
import * as Sentry from "@sentry/react";
import { State } from "src/state/state";
import { useMediaQuery } from "react-responsive";
import { UIVariants } from "src/state/globalUIVariant/types";

export const Router = () => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({
    query: "(max-width: 874px)",
  });

  const sessionId = useSelector(
    (state: State) => state.session?.sessionId as string,
  );
  const homeVsMenuUiVariant = useSelector(
    (state: State) => state.globalUIVariant.homeVsMenuVariant as UIVariants,
  );

  const [isLoading, setIsLoading] = useState(true);
  const [isLocationSelectorVisible, setIsLocationSelectorVisible] =
    useState(false);

  const initializeAuth = useCallback(async () => {
    const userId = getItemFromLocalStorage("USER_ID");
    const refreshToken = getItemFromLocalStorage("REFRESH_TOKEN");

    if (userId && refreshToken) {
      const { response: customer } =
        await getCustomerFromDatabaseAction(userId)(dispatch);
      return customer;
    }
  }, [dispatch]);

  const initializeClientConfig = useCallback(async () => {
    const response = await setClientConfigAction()(dispatch);
    const restaurantId = response.response.restaurantId;

    const [initializeResponse, customer] = await Promise.all([
      initializeState(restaurantId, dispatch),
      initializeAuth(),
    ]);

    const { restaurant } = initializeResponse;
    const currentPath = window.location.pathname;

    if (
      restaurant.locations.length === 1 ||
      currentPath.includes("catering-invoice")
    ) {
      selectLocationAction(restaurant.locations[0])(dispatch);
    } else {
      setIsLocationSelectorVisible(true);
    }

    Sentry.setContext("user_info", {
      restaurantId: restaurant.id,
      restaurantName: restaurant.restaurantName,
      customerId: customer ? customer.id : "GUEST",
      customerName: customer
        ? `${customer.firstName} ${customer.lastName}`
        : "GUEST",
      sessionId,
    });

    setIsLoading(false);

    logWebsiteVisitToAnalytics(isMobile);
  }, [dispatch, window.location.pathname, isMobile]);

  useEffect(() => {
    initializeClientConfig();
  }, []);

  useEffect(() => {
    // Required to reset the router if routes change
    if (!isLoading) {
      setIsLoading(true);
      setIsLoading(false);
    }
  }, [homeVsMenuUiVariant]);

  if (isLoading) {
    return (
      <div className={styles.Router} data-testid="router-loading">
        <div className={styles.loading}>
          <SkeletonLoading />
        </div>
      </div>
    );
  }

  return (
    <BrowserRouter>
      <div className={styles.Router}>
        <Header />
        <div className={styles.content}>
          <Routes>
            <Route path={getGenericHomePath()} element={<Home />} />
            <Route path={getGenericMenuPath()} element={<Menu />} />
            <Route path={getGenericCateringPath()} element={<Catering />} />
            <Route path={getGenericDealPath()} element={<Deal />} />
            <Route path={getGenericItemPath()} element={<Item />} />
            <Route path={getGenericSignInPath()} element={<SignIn />} />
            <Route
              path={getGenericForgotPasswordPath()}
              element={<ForgotPassword />}
            />
            <Route
              path={getGenericResetPasswordPath()}
              element={<ResetPassword />}
            />
            <Route path={getGenericSignUpPath()} element={<SignUp />} />
            <Route path={getGenericTermsPath()} element={<Terms />} />
            <Route path={getGenericAccountPath()} element={<Account />} />
            <Route
              path={getGenericPersonalInfoPath()}
              element={<PersonalInfo />}
            />
            <Route path={getGenericCartPath()} element={<Cart />} />
            <Route
              path={getGenericCateringCartPath()}
              element={<CateringCart />}
            />
            <Route
              path={getGenericCateringCheckoutPath()}
              element={<CateringCheckout />}
            />
            <Route path={getGenericCheckoutPath()} element={<Checkout />} />
            <Route
              path={getCateringCheckoutPath()}
              element={<CateringCheckout />}
            />
            <Route path={getGenericReceiptPath()} element={<Receipt />} />
            <Route
              path={getGenericCateringInvoicePath()}
              element={<CateringInvoice />}
            />
            <Route
              path={getGenericCategoryPath()}
              element={<Navigate replace to={getGenericHomePath()} />}
            />
            <Route
              path={getGenericWildcardRoute()}
              element={<Navigate replace to={getGenericHomePath()} />}
            />
          </Routes>
        </div>
        <Footer />
        <SelectLocationModal
          testId="root-select-location-modal"
          isOpen={isLocationSelectorVisible}
          onClose={() => setIsLocationSelectorVisible(false)}
        />
      </div>
    </BrowserRouter>
  );
};
