// Utililty functions for interacting with AWS cognito
import {
  CognitoUserPool,
  CognitoUserAttribute,
  CognitoUser,
  AuthenticationDetails,
} from "amazon-cognito-identity-js";
import { CognitoIdentityServiceProvider, config as AwsConfig } from "aws-sdk";
import { getEnvVariable } from "src/config/getConfig";
import {
  AdminDeleteUserRequest,
  AdminInitiateAuthRequest,
  AdminUpdateUserAttributesRequest,
} from "aws-sdk/clients/cognitoidentityserviceprovider";
import { getClientConfig } from "src/state/clientConfig/utils";
import { v4 as uuid } from "uuid";
import {
  putItemIntoLocalStorage,
  getItemFromLocalStorage,
  removeItemFromLocalStorage,
} from "src/common/localStorage";
import { captureManualSentryException } from "src/common/sentry";

const testRefreshToken = uuid();
const testIdToken = uuid();

export const signUpUserInAWSCognito = async (
  email: string,
  password: string,
  fName: string,
  lName: string,
): Promise<{
  userId: string;
  refreshToken: string;
}> => {
  if (getItemFromLocalStorage("mockEmailAddressAlreadyExists")) {
    throw new Error("An account with the given email already exists.");
  }

  if (getItemFromLocalStorage("mockSignUpUserInAWSCognito")) {
    return {
      userId: getItemFromLocalStorage("mockSignUpUserInAWSCognito") as string,
      refreshToken: testRefreshToken,
    };
  }

  const [awsCognitoUserPoolId, awsCognitoClientId] = await Promise.all([
    getClientConfig("awsCognitoUserPoolId"),
    getClientConfig("awsCognitoClientId"),
  ]);

  AwsConfig.update({
    region: getEnvVariable("AWS_REGION"),
    accessKeyId: getEnvVariable("AWS_ACCESS_KEY"),
    secretAccessKey: getEnvVariable("AWS_SECRET_ACCESS_KEY"),
    httpOptions: {
      timeout: 180000,
    },
    maxRetries: 3,
  });

  let userPool;

  try {
    userPool = new CognitoUserPool({
      UserPoolId: awsCognitoUserPoolId,
      ClientId: awsCognitoClientId,
    });
  } catch (e) {
    captureManualSentryException(
      new Error(
        `Error creating CognitoUserPool with UserPoolId: ${awsCognitoUserPoolId} and ClientId: ${awsCognitoClientId}`,
      ),
    );
    throw e;
  }

  const userAttributes = [
    new CognitoUserAttribute({ Name: "email", Value: email }),
    new CognitoUserAttribute({ Name: "given_name", Value: fName }),
    new CognitoUserAttribute({ Name: "family_name", Value: lName }),
  ];

  return new Promise((resolve, reject) => {
    userPool.signUp(
      email,
      password,
      userAttributes,
      [],
      async (err, signUpResult) => {
        if (signUpResult && !err) {
          await confirmUserInAWSCognito(email);
          const userSession = await logInUserInAWSCognito(email, password);

          resolve({
            userId: signUpResult.userSub,
            refreshToken: userSession.refreshToken,
          });
        } else {
          reject(err);
        }
      },
    );
  });
};

export const logInUserInAWSCognito = async (
  email: string,
  password: string,
): Promise<{
  userId: string;
  refreshToken: string;
}> => {
  if (getItemFromLocalStorage("mockIncorrectCredentials")) {
    throw new Error("Incorrect username or password");
  }

  if (getItemFromLocalStorage("mockLogInUserInAWSCognito")) {
    return {
      userId: getItemFromLocalStorage("mockLogInUserInAWSCognito") as string,
      refreshToken: testRefreshToken,
    };
  }

  const [awsCognitoUserPoolId, awsCognitoClientId] = await Promise.all([
    getClientConfig("awsCognitoUserPoolId"),
    getClientConfig("awsCognitoClientId"),
  ]);

  AwsConfig.update({
    region: getEnvVariable("AWS_REGION"),
    accessKeyId: getEnvVariable("AWS_ACCESS_KEY"),
    secretAccessKey: getEnvVariable("AWS_SECRET_ACCESS_KEY"),
    httpOptions: {
      timeout: 180000,
    },
    maxRetries: 3,
  });

  let userPool;

  try {
    userPool = new CognitoUserPool({
      UserPoolId: awsCognitoUserPoolId,
      ClientId: awsCognitoClientId,
    });
  } catch (e) {
    captureManualSentryException(
      new Error(
        `Error creating CognitoUserPool with UserPoolId: ${awsCognitoUserPoolId} and ClientId: ${awsCognitoClientId}`,
      ),
    );
    throw e;
  }

  return new Promise((resolve, reject) => {
    const authenticationDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    });

    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: userPool,
    });

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) => {
        const userId = cognitoUser.getUsername();
        const refreshToken = result.getRefreshToken().getToken();

        putItemIntoLocalStorage("USER_ID", userId);
        putItemIntoLocalStorage("REFRESH_TOKEN", refreshToken);

        resolve({
          userId,
          refreshToken,
        });
      },
      onFailure: (err) => {
        reject(err);
      },
    });
  });
};

export const updateUserEmailInAWSCognito = async (
  userId: string,
  email: string,
): Promise<void> => {
  if (getItemFromLocalStorage("mockEmailAddressAlreadyExists")) {
    throw new Error("An account with the given email already exists.");
  }

  if (getItemFromLocalStorage("mockUpdateUserEmailInAWSCognito")) {
    return;
  }

  const awsCognitoUserPoolId = await getClientConfig("awsCognitoUserPoolId");

  AwsConfig.update({
    region: getEnvVariable("AWS_REGION"),
    accessKeyId: getEnvVariable("AWS_ACCESS_KEY"),
    secretAccessKey: getEnvVariable("AWS_SECRET_ACCESS_KEY"),
    httpOptions: {
      timeout: 180000,
    },
    maxRetries: 3,
  });

  const cognitoIdentityServiceProvider = new CognitoIdentityServiceProvider();
  const params: AdminUpdateUserAttributesRequest = {
    UserPoolId: awsCognitoUserPoolId,
    Username: userId,
    UserAttributes: [
      {
        Name: "email",
        Value: email,
      },
    ],
  };

  await cognitoIdentityServiceProvider
    .adminUpdateUserAttributes(params)
    .promise();

  const verifyParams: AdminUpdateUserAttributesRequest = {
    UserPoolId: awsCognitoUserPoolId,
    Username: email,
    UserAttributes: [
      {
        Name: "email_verified",
        Value: "true",
      },
    ],
  };
  await cognitoIdentityServiceProvider
    .adminUpdateUserAttributes(verifyParams)
    .promise();
};

export const getIdTokenFromAWSCognito = async (
  refreshToken: string,
): Promise<string> => {
  if (getItemFromLocalStorage("mockGetIdTokenFromAWSCognito")) {
    return testIdToken;
  }

  AwsConfig.update({
    region: getEnvVariable("AWS_REGION"),
    accessKeyId: getEnvVariable("AWS_ACCESS_KEY"),
    secretAccessKey: getEnvVariable("AWS_SECRET_ACCESS_KEY"),
    httpOptions: {
      timeout: 180000,
    },
    maxRetries: 3,
  });

  const userPoolId = getEnvVariable("AWS_COGNITO_ANONYMOUS_USER_POOL_ID");
  const clientId = getEnvVariable("AWS_COGNITO_ANONYMOUS_CLIENT_ID");

  const cognitoIdentityServiceProvider = new CognitoIdentityServiceProvider();
  const params: AdminInitiateAuthRequest = {
    UserPoolId: userPoolId,
    ClientId: clientId,
    AuthFlow: "REFRESH_TOKEN_AUTH",
    AuthParameters: {
      REFRESH_TOKEN: refreshToken,
    },
  };

  const result = await cognitoIdentityServiceProvider
    .adminInitiateAuth(params)
    .promise();

  const idToken = result.AuthenticationResult?.IdToken as string;

  return idToken;
};

export const deleteUserFromAWSCognito = async (
  userId: string,
): Promise<void> => {
  if (getItemFromLocalStorage("mockDeleteUserFromAWSCognito")) {
    return;
  }

  AwsConfig.update({
    region: getEnvVariable("AWS_REGION"),
    accessKeyId: getEnvVariable("AWS_ACCESS_KEY"),
    secretAccessKey: getEnvVariable("AWS_SECRET_ACCESS_KEY"),
    httpOptions: {
      timeout: 180000,
    },
    maxRetries: 3,
  });

  const userPoolId = getEnvVariable("AWS_COGNITO_ANONYMOUS_USER_POOL_ID");

  const cognitoIdentityServiceProvider = new CognitoIdentityServiceProvider();
  const params: AdminDeleteUserRequest = {
    UserPoolId: userPoolId,
    Username: userId,
  };

  await cognitoIdentityServiceProvider.adminDisableUser(params).promise();
  await cognitoIdentityServiceProvider.adminDeleteUser(params).promise();
};

export const signOutUser = async (): Promise<void> => {
  if (getItemFromLocalStorage("mockSignOutUser")) {
    return;
  }

  removeItemFromLocalStorage("USER_ID");
  removeItemFromLocalStorage("REFRESH_TOKEN");

  return;
};

// Helper to automatically confirm a user in AWS Cognito
export const confirmUserInAWSCognito = async (email: string): Promise<void> => {
  if (getItemFromLocalStorage("mockConfirmUserInAWSCognito")) {
    return;
  }

  const [awsCognitoUserPoolId] = await Promise.all([
    getClientConfig("awsCognitoUserPoolId"),
  ]);

  AwsConfig.update({
    region: getEnvVariable("AWS_REGION"),
    accessKeyId: getEnvVariable("AWS_ACCESS_KEY"),
    secretAccessKey: getEnvVariable("AWS_SECRET_ACCESS_KEY"),
    httpOptions: {
      timeout: 180000,
    },
    maxRetries: 3,
  });

  const cognitoIdentityServiceProvider = new CognitoIdentityServiceProvider();
  const params: AdminUpdateUserAttributesRequest = {
    UserPoolId: awsCognitoUserPoolId,
    Username: email,
    UserAttributes: [
      {
        Name: "email_verified",
        Value: "true",
      },
    ],
  };
  await cognitoIdentityServiceProvider
    .adminUpdateUserAttributes(params)
    .promise();

  return new Promise((resolve, reject) => {
    cognitoIdentityServiceProvider.adminConfirmSignUp(
      {
        UserPoolId: awsCognitoUserPoolId,
        Username: email,
      },
      (err, confirmResult) => {
        if (confirmResult && !err) {
          resolve();
        } else {
          reject(err);
        }
      },
    );
  });
};

export const sendForgotPasswordEmailInAWSCognito = async (
  email: string,
): Promise<void> => {
  if (getItemFromLocalStorage("mockSendForgotPasswordEmailInAWSCognito")) {
    return;
  }

  const [awsCognitoUserPoolId, awsCognitoClientId] = await Promise.all([
    getClientConfig("awsCognitoUserPoolId"),
    getClientConfig("awsCognitoClientId"),
  ]);

  AwsConfig.update({
    region: getEnvVariable("AWS_REGION"),
    accessKeyId: getEnvVariable("AWS_ACCESS_KEY"),
    secretAccessKey: getEnvVariable("AWS_SECRET_ACCESS_KEY"),
    httpOptions: {
      timeout: 180000,
    },
    maxRetries: 3,
  });

  let userPool;

  try {
    userPool = new CognitoUserPool({
      UserPoolId: awsCognitoUserPoolId,
      ClientId: awsCognitoClientId,
    });
  } catch (e) {
    captureManualSentryException(
      new Error(
        `Error creating CognitoUserPool with UserPoolId: ${awsCognitoUserPoolId} and ClientId: ${awsCognitoClientId}`,
      ),
    );
    throw e;
  }

  const cognitoUser = new CognitoUser({
    Username: email,
    Pool: userPool,
  });

  return new Promise((resolve, reject) => {
    cognitoUser.forgotPassword({
      onSuccess: (result) => {
        resolve(result);
      },
      onFailure: (err) => {
        reject(err);
      },
    });
  });
};

export const confirmForgotPasswordInAWSCognito = async (
  email: string,
  verificationCode: string,
  newPassword: string,
): Promise<string> => {
  if (getItemFromLocalStorage("mockResetPasswordIncorrectVerificationCode")) {
    throw new Error("Invalid verification code provided, please try again.");
  }

  if (getItemFromLocalStorage("mockConfirmForgotPasswordInAWSCognito")) {
    return "SUCCESS";
  }

  const [awsCognitoUserPoolId, awsCognitoClientId] = await Promise.all([
    getClientConfig("awsCognitoUserPoolId"),
    getClientConfig("awsCognitoClientId"),
  ]);

  AwsConfig.update({
    region: getEnvVariable("AWS_REGION"),
    accessKeyId: getEnvVariable("AWS_ACCESS_KEY"),
    secretAccessKey: getEnvVariable("AWS_SECRET_ACCESS_KEY"),
    httpOptions: {
      timeout: 180000,
    },
    maxRetries: 3,
  });

  let userPool;

  try {
    userPool = new CognitoUserPool({
      UserPoolId: awsCognitoUserPoolId,
      ClientId: awsCognitoClientId,
    });
  } catch (e) {
    captureManualSentryException(
      new Error(
        `Error creating CognitoUserPool with UserPoolId: ${awsCognitoUserPoolId} and ClientId: ${awsCognitoClientId}`,
      ),
    );
    throw e;
  }

  const cognitoUser = new CognitoUser({
    Username: email,
    Pool: userPool,
  });

  return new Promise((resolve, reject) => {
    cognitoUser.confirmPassword(verificationCode, newPassword, {
      onSuccess: (result) => {
        resolve(result);
      },
      onFailure: (err) => {
        reject(err);
      },
    });
  });
};
