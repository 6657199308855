import styles from "src/components/Card/styles.module.scss";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { Image } from "src/components";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { State } from "src/state/state";

interface CardProps {
  className?: string;
  testId?: string;
  image: string | undefined;
  link: string;
  title: string;
  description?: string;
  price?: number;
  showAddIndicator?: boolean;
  onClick?: () => void;
}

export const Card = ({
  className,
  testId,
  link,
  image,
  title,
  description,
  price,
  showAddIndicator = true,
  onClick,
}: CardProps) => {
  const isOnlineOrderingEnabled = useSelector(
    (state: State) =>
      state.restaurants.currentRestaurant &&
      state.restaurants.currentRestaurant.restaurantSettings
        .isOnlineOrderingEnabled,
  );

  const maxLengthOfText = useMemo(() => {
    if (image) {
      return 60;
    }

    return 130;
  }, [image, description]);

  return (
    <Link
      data-testid={testId}
      to={link}
      className={classNames(styles.Card, className)}
      onClick={onClick}
    >
      <div className={styles.textContainer}>
        <h5
          className={classNames(styles.title, {
            [styles.justTitle]: !description && !price,
          })}
        >
          {title}
        </h5>
        {description && (
          <p className={styles.description}>
            {description.length > maxLengthOfText
              ? `${description.slice(0, maxLengthOfText)}...`
              : description}
          </p>
        )}
        {price !== undefined && (
          <p className={styles.price}>{`$${price.toFixed(2)}`}</p>
        )}
      </div>
      {image && (
        <Image className={classNames(styles.image)} src={image} alt={title} />
      )}
      {showAddIndicator && (
        <div
          className={classNames(styles.addIndicator, {
            [styles.addIndicatorWithImage]: image !== undefined,
          })}
        >
          <p className={styles.addText}>
            {isOnlineOrderingEnabled ? "Add" : "View"}
          </p>
        </div>
      )}
    </Link>
  );
};
