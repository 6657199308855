import { UIVariants } from "src/state/globalUIVariant/types";

export const selectRandomUIVariant = (totalVariants: number): UIVariants => {
  if (localStorage.getItem("mockUiVariant")) {
    return parseInt(
      localStorage.getItem("mockUiVariant") as string,
    ) as UIVariants;
  }

  const randomVariant = Math.ceil(Math.random() * totalVariants) as UIVariants;
  return randomVariant;
};
